import React, { Component, Fragment } from "react";
import config from "../../../config.json";
import myCustomAxios from "../../../services/myAxios";
import Catalogo from "../../../util/catalogo";
import { setToken } from "../../../actions/authActions";
import { Button, Row, Col, CardHeader, Card, CardBody, Collapse, Modal, ModalHeader, ModalBody, ModalFooter,Breadcrumb, BreadcrumbItem ,FormGroup, Label} from "reactstrap";
import cx from "classnames";
import { FaFileInvoice, FaPencilAlt} from "react-icons/fa";
import {faHome, faAngleDown} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation, WithTranslation } from 'react-i18next';
import Select from 'react-select';
import Loader from "react-loaders";
import SubProcesos from "./components/subProcesos";

class ProxyPass extends Component {
    constructor(props) {
        super(props);
        this.state = {
          values:{
            id:0,
            title:"",
            idTipoProceso:0,
            idUnidadOrg:{value:0, label:""},
            paisesBeneficiarios:{value:0, label:""},
            esPlantilla:false,
            idPlantillaProc:0,
          },
          procurementId:0,
          listTiposProceso:[],
          listPriority:[{value:0, label:""},{value:1, label:this.props.t("Costa Rica")},{value:2, label:this.props.t("Panamá")},{value:3, label:this.props.t("Guatemala")}],
          listUnOrg:[],//[{value:0, label:""},{value:1, label:this.props.t("B5416-LCR, PAMCO, Costa Rica")}],          
          forecasts: [],
          Templates:[],
          listProcurements:[],
          ProjectsV:[],
          WorkFlows:[],
          ProjectsBQ:[],
          tableTemplates:"",
          idTipoProcesoSe:{value:0, label:""},
          idPlantillaSe:{value:0, label:""},
          idProjectBQ:{value: "", label: "", description:"", project_Manager_Name:"", start_date:new Date(), end_date:new Date()},
          loading: false,
          modal: false,
          modalMsg: false,
          modalSubProc: false,
          modalProcurement:false,
          mensajeOperacion: "",
          accordion: [false],
          listPhases:[],
        };
    
        //this.handleEdit = this.handleEdit.bind(this);
        this.renderProcurementsTable = this.renderProcurementsTable.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleMsg = this.toggleMsg.bind(this);
        this.toggleSubProc = this.toggleSubProc.bind(this);
        this.toggleProcurement = this.toggleProcurement.bind(this);
        this.handleChangeUnidadOrg = this.handleChangeUnidadOrg.bind(this);
        this.handlePaisBenef = this.handlePaisBenef.bind(this);
        this.handleSaveProcurement = this.handleSaveProcurement.bind(this);    
        this.handleTipoProceso = this.handleTipoProceso.bind(this);    
        this.handlePlantilla = this.handlePlantilla.bind(this);
      }

      async componentDidMount() {
        if (this.props.location.search !== "") {
          let parameters = this.props.location.search.replace("?","").split("=");   
          console.log(parameters[0])
        }


        if(this.props.location.query)
        {
            var data = this.props.location.query;
            this.getProcurements(1, data.procurementId);
        }
        else
        {
            var data = {};
            this.props.history.push({
                pathname: "/apps/listProcurements",
                query:data,
                })
        }
      }

      EnviarProcurementPage(procurementId, driveFolderId, title, listTransitions, editProccess, partialOfferId, code, wPhaseId){
        if(procurementId>0)
        {
            var data = {procurementId:procurementId, driveFolderId:driveFolderId, title:title, listTransitions:listTransitions,
                         editProccess:editProccess, partialOfferId:partialOfferId, procCode:code, wPhaseId:wPhaseId, isTemplate:false};
            this.props.history.push({
                pathname: "/apps/procurement",
                query:data,
              })
        }
    }

    render() {
        //const mensajeOperacion = this.state.mensajeOperacion;
    
        return (
          <Fragment>
            <TransitionGroup>
              <CSSTransition
                component="div"
                classNames="TabsAnimation"
                appear={true}
                timeout={1500}
                enter={false}
                exit={false}
              >
                <div>

    <div className="app-page-title app-page-title-simple">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div>
                <div className="page-title-head center-elem">
                  <span className={cx("d-inline-block pe-2")}>
                    <i className={"pe-7s-id"} />
                  </span>
                  <span className="d-inline-block">{"E-Proeri"}</span>
                </div>
                <div className={cx("page-title-subheading opacity-10" )}>
                    <Breadcrumb>
                      <BreadcrumbItem>
                        <a href="https://colorlib.com/" onClick={(e) => e.preventDefault()}>
                          <FontAwesomeIcon icon={faHome} />
                        </a>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <a href="https://colorlib.com/" onClick={(e) => e.preventDefault()}>
                        Gestión de licitaciones
                        </a>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>Licitaciones en proceso</BreadcrumbItem>
                    </Breadcrumb>
                </div>
              </div>
            </div>
          </div>
        </div>

                </div>
              </CSSTransition>
            </TransitionGroup>
          </Fragment>
        );
      }

      async getProcurements(idOficina, procurementId) {
        try {
          if (idOficina == null) idOficina = 0;
      
          const bod = { Id: procurementId, Title:"", TipoConsulta:"Procesos", UserId:this.props.auth.user.idUsuario};
          const values = JSON.stringify(bod);
      
            const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
        
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            let url = process.env.REACT_APP_API + config.API_ESOURCING_PROCUREMENT;
            this.setState({loading:true});  
            
            customAxios.patch(url,
                values,
                { headers }).then((res) => {
                  //console.log(res.data)
                //this.setState({ listProcurements: res.data, loading: false });
                //this.setState({countItems: res.data.length})
                this.EnviarProcurementPage(res.data[0].procurementId, 
                    res.data[0].driveFolderId, 
                    res.data[0].title, 
                    res.data[0].listTransitions, 
                    res.data[0].editProccess, 
                    res.data[0].partialOfferId, 
                    res.data[0].code, 
                    res.data[0].wPhaseId)
              })
              .catch((err) => {
                this.setState({loading:false});  
                console.log("AXIOS ERROR: ", err);
              })
        } catch (error) {
          console.log(error);
        }
      }

}

  const mapStateToProps = (state) => ({
    auth: state.auth,
  });
  
  const mapDispatchToProps = (dispatch) => {
      return {
        setToken: (token) => {
          dispatch(setToken(token));
        }
      }
    };

    export default withTranslation()(withRouter(connect(mapStateToProps,mapDispatchToProps)(ProxyPass)));    