import React, { Fragment , Suspense} from "react";
import { connect } from "react-redux";
import { Route, Redirect,Switch } from "react-router-dom";
import cx from "classnames";
import { withRouter } from "react-router-dom";
import Login from '../../containers/Login'
//import LoginG from "../../containers/LoginG";
import Loader from "react-loaders";
import ResizeDetector from "react-resize-detector";

import AppMain from "../../Layout/AppMain";
import Activation from "../../Pages/Account/Activation";
import ProxyPass from "../Applications/esourcing/proxyPass";
import CRM from "../Dashboards/CRM";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closedSmallerSidebar: false,
    };
  }

  componentDidMount() {
    console.log("props searchn")
      console.log(this.props.location.search)
  }

  render() {
    const loaderContainerStyle = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // Para que ocupe toda la altura de la ventana
      width: '100vw',  // Para que ocupe toda la anchura de la ventana
    };

    let {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
    } = this.props;

    //console.log('autenticado: '+this.props.auth.isAuthenticated)
    let content = !!this.props.auth.isAuthenticated ?
    (
      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <Fragment>
            <div
              className={cx(
                "app-container app-theme-" + colorScheme,
                { "fixed-header": enableFixedHeader },
                { "fixed-sidebar": enableFixedSidebar || width < 1250 },
                { "fixed-footer": enableFixedFooter },
                { "closed-sidebar": enableClosedSidebar || width < 1250 },
                {
                  "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
                },
                { "sidebar-mobile-open": enableMobileMenu },
                { "body-tabs-shadow-btn": enablePageTabsAlt }
              )}>
              <AppMain queryIdProcedurement = {this.props.location.search} /> 
            </div>
          </Fragment>
        )}
      />
    ):
    <Login/>
return (
  <div>
      {content}
  </div>
);

  }
}


const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
  auth: state.auth,
});

export default withRouter(connect(mapStateToProp)(Main));
