
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { Link } from "react-router-dom";


class Activation extends Component {

    constructor(props) {
      super(props);
      this.navigate = this.navigate.bind(this);
      this.state = {
        activationMessage : "",
        loading : true,
        ok: false
    };
}


  componentDidMount(){

    const activateAccountLink = "https://localhost:7005/AuthManual/activate";
    const params = new URLSearchParams(window.location.search);


        const token = params.get('token');

        if(token){
            axios.post(activateAccountLink, {
                Token : token 
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(resp=>{
    
                if(resp.data.operacionConExito){
                    this.setState({activationMessage : "Su cuenta fue activada exitosamente", loading: false, ok: true});
                }else{
                    this.setState({activationMessage : resp.data.error || "Error", loading: false});
                }
    
            }).catch(err=>{
                this.setState({activationMessage : "Error de petición o token inválido", loading: false});
            });
        }

  }

  navigate(){
    //console.log(this.props);
    //this.props.history.push("/login");
    window.location.href = '/login';
  }

  render (){


    /*
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background-color: #A7FCA2;
    */

    return <div style={{display: 'flex', flexDirection: 'column',justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: "#A7FCA2"}}>
        <h1 className="h1">{this.state.loading ? "Verificando..." :  this.state.activationMessage}</h1>
        {this.state.ok && 
            <button className="btn btn-lg btn-dark" onClick={this.navigate}>Ir a Login</button>            
            }
    </div>
  }

};

export default withRouter(Activation);