import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";

import { ToastContainer } from "react-toastify";

//const UserPages = lazy(() => import("../../DemoPages/UserPages"));
const Applications = lazy(() => import("../../DemoPages/Applications"));
const Dashboards = lazy(() => import("../../DemoPages/Dashboards"));

/*const Widgets = lazy(() => import("../../DemoPages/Widgets"));
const Elements = lazy(() => import("../../DemoPages/Elements"));
const Components = lazy(() => import("../../DemoPages/Components"));
const Charts = lazy(() => import("../../DemoPages/Charts"));
const Forms = lazy(() => import("../../DemoPages/Forms"));
const Tables = lazy(() => import("../../DemoPages/Tables"));*/


const AppMain = (params) => {   

    return (       
        <Fragment>
            {/* Applications */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse"/>
                        </div>
                        <h6 className="mt-3">
                            Por favor espere mientras se carga el portal
                            <small>e-proeri.org</small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/apps" component={Applications} /*queryIdProcedurement = {this.props.location.search}*//>
            </Suspense>

            {/* Dashboards */}
            
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            Por favor espere mientras se carga el portal
                            <small>e-proeri.org</small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/dashboards" component={Dashboards }/>
            </Suspense>
            

            <Route exact path="/" render={() => (
                params.queryIdProcedurement == '' ?
                <Redirect to="/dashboards/crm"/> :
                <Redirect to={"/apps/proxyPass" + params.queryIdProcedurement }/>
            )}/>
            <Route exact path="/login" render={() => (
                <Redirect to="/login"/> 
            )}/>

            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;
