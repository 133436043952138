import React, { Component, Fragment } from "react";
import config from "../../../../config.json";
import myCustomAxios from "../../../../services/myAxios";
import { setToken } from "../../../../actions/authActions";
import { Button, Row, Col, Card, CardBody,  Modal, ModalHeader, ModalBody, ModalFooter, Alert, CardFooter} from "reactstrap";
import { FaRegArrowAltCircleUp, FaRegArrowAltCircleDown} from "react-icons/fa";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation, WithTranslation } from 'react-i18next';
import Loader from "react-loaders";

class SubProcesos extends Component {
    constructor(props) {
        super(props);
        this.state = {
          values:{
            id:0,
            description:"",
          },
          listSubProcesos: [],
          tableTemplates:"",
          loading: false,
          modal: false,
          modalMsg: false,
          mensajeOperacion: "",
        };
    
        //this.handleEdit = this.handleEdit.bind(this);
        this.renderSubProcTable = this.renderSubProcTable.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleMsg = this.toggleMsg.bind(this);
        this.handleSaveSubProceso = this.handleSaveSubProceso.bind(this);
      }

      handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
          values: {
            ...this.state.values,
            [name]: value,
          },
        });
        //console.log(this.state.values)
      };

      toggle() {
        this.setState({
          modal: !this.state.modal,
        });
      }

      toggleMsg() {
        this.setState({
          modalMsg: !this.state.modalMsg,
        });
      }

      componentDidMount() {
        this.getLotes(this.props.procurementId);
      }

      renderSubProcTable(listCriterion) {
        const columns = [
          {
            name: "",
            width: "8rem",
            cell: (row) => {
              return (
                <Fragment>
                  <button
                    className="btn btn-outline-primary"
                    //onClick={(id) => this.EnviarProcurementPage(row.id)}
                  >
                    <FaRegArrowAltCircleDown size={16} />
                  </button>
                  &nbsp;
                  <button
                    className="btn btn-outline-primary"
                    //onClick={(id) => this.EnviarProcurementPage(row.id)}
                  >
                    <FaRegArrowAltCircleUp size={16} />
                  </button>

                </Fragment>
              );
            },
          },
          {
            name: this.props.t('Descripción'),
            id: "code",
            //width: "25rem",
            selector: (row) => row.description,
            sortable: true,
          },
          {
            name: this.props.t('Numero'),
            //width: "10rem",
            selector: (row) => row.number,
            sortable: true,
          },
        ];
    
        return (
          <div>
            <DataTable
              data={listCriterion}
              columns={columns}
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 15, 25, 50]}
              paginationComponentOptions={{
                rowsPerPageText: "Registros por pagina:",
                rangeSeparatorText: "de",
              }}
            />
          </div>
        );
      }

      render() {
        let contents = this.state.loading ? (
          <p>
            <em>{this.props.t('Loading')}...</em>
          </p>
        ) : (
          this.renderSubProcTable(this.state.listSubProcesos)
        );
    
        //const mensajeOperacion = this.state.mensajeOperacion;
    
        return (
                <div>
                  <Card className="main-card mb-1">
                  <Row>
                  <Col md="12">
                    <div className="m-2">
                        <label htmlFor="exampleInputEmail1" className="form-label"> {"Descripción"} </label>
                        
                        <input
                                type="text"
                                className="form-control"
                                id="description"
                                aria-describedby="emailHelp"
                                name="description"
                                value={this.state.values.description}
                                ref="description"
                                onChange={this.handleChange}
                                ></input>
                        </div>                                                            
                          {this.state.loading ? <div><Loader type="ball-beat" />
                                  <p>
                                    <em>Creando registro de lote...</em>
                                  </p></div> : ""}                  
                  </Col>  
                  </Row>
                  <CardFooter>
                    <Button color="primary" 
                                onClick={this.handleSaveSubProceso}
                                >
                              {"Añadir"}
                              </Button>{" "}                    
                  </CardFooter>
                  </Card>  
                  <Card>
                  <Row>
                    <Col md="12">
                        <h3 style={{paddingLeft: "10px", paddingTop:"10px"}}>{this.props.t('Lotes definidos para el anuncio')}</h3>
                        <div className="px-0">
                          <CardBody className="p-2">
                            {contents} <br/></CardBody>
                        </div>
                    </Col>
                  </Row>
                  <Alert className="mbg-3 m-2" color="info" isOpen={this.state.visible} toggle={this.onDismiss}>
                    <span className="pl-2">
                    {/*}<FontAwesomeIcon icon={faQuestionCircle} />{*/}
                    </span>
                    Añada un nuevo lote insertando una descripción en el campo a continuación, y haga click en el boton [Añadir].
                  </Alert>
                  </Card>
                  <Modal
                      isOpen={this.state.modalMsg}
                      toggle={this.toggleMsg}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggleMsg}>{this.props.t('Project')}s</ModalHeader>
                      <ModalBody>
                        <p>{this.state.mensajeOperacion}</p>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={this.toggleMsg}>
                        {this.props.t('OK')}
                        </Button>
                      </ModalFooter>
                    </Modal>

                </div>
        );
      }

      async getLotes(procurementId) {
        try {
      
          const bod = { Id: 0, ProcurementId:procurementId};
          const values = JSON.stringify(bod);
      
            const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
        
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            let url = process.env.REACT_APP_API + config.API_ESOURCING_SUBPROCESO;

            customAxios.patch(url,
                values,
                { headers }).then((res) => {
                this.setState({ listSubProcesos: res.data, loading: false });
                //console.log(res.data)
                /*let valores = [];
                let item = {
                  value: 0,
                  label: "",
                };
                res.data.forEach(element => {
                    let item = {value: element.id, label: this.props.t(element.name)}
                    valores.push(item);
                });
      
                this.setState({ProjectsV: valores});*/
                //this.setState({countItems: valores.length});
              })
              .catch((err) => {
                console.log("AXIOS ERROR: ", err);
              })
        } catch (error) {
          console.log(error);
        }
      }

      async handleSaveSubProceso() {
        try {
    
          if(this.state.values.description==='')
            {
              this.setState({ mensajeOperacion: "Por favor indique la descripción para el lote." });
              this.toggleMsg();
              return;
            }
  
          const bod = {  Id: this.state.values.id, 
            ProcurementId:this.props.procurementId, 
            Description:this.state.values.description, 
            Number:0.,
            Status:1};
          const values = JSON.stringify(bod);
          
          //console.log(values)
          //return;
          this.setState({loading: false});
      
          const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
            
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            this.setState({loading: true});
    
            let url = process.env.REACT_APP_API + config.API_ESOURCING_SUBPROCESO;
    
            customAxios.post(url,
              values,
                { headers }).then((res) => {
                    this.setState({loading: false});
                    if (!res.data.operacionConExito) {
                      this.setState({ mensajeOperacion: res.data.error });
                      this.setState({loading: false});
                      this.toggleMsg();
                    } 
                    else {
                    this.setState({loading: false});
                    this.setState({ mensajeOperacion: "Lote agregado con éxito." });
                    this.toggleMsg();
                    this.getLotes(this.props.procurementId);
                    }
                })
              .catch((err) => {
                this.setState({loading: false});
                console.log("AXIOS ERROR: ", err);
                //this.setState({loadingG: false});
              })
        } catch (error) {
          console.log(error);
        }
      }  

}

  const mapStateToProps = (state) => ({
    auth: state.auth,
  });
  
  const mapDispatchToProps = (dispatch) => {
      return {
        setToken: (token) => {
          dispatch(setToken(token));
        }
      }
    };

    export default withTranslation()(withRouter(connect(mapStateToProps,mapDispatchToProps)(SubProcesos)));    